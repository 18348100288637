<template lang='pug'>
#Orders.main-page.page-noScroll.pb-0.position-relative
    loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    v-row#page-head.d-flex.align-center.justify-md-space-between(v-if='!loading')
        v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='auto' md='auto' style="z-index:1;")
            .d-flex.align-center
                h1.my-0 {{$t(`ORDER.${this.isWiadvance() ? 'WIADVANCE_TITLE' : 'TITLE'}`)}}
                v-btn.ml-3.add-deal-btn(v-if='checkWiadvanceReadonly()' @click='showAddOrderDialog()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
                    v-icon(size='16') icon-add
        v-col.px-0.pt-4.pt-md-2(cols='auto' v-if='isByIndustryShow')
            v-row.justify-md-end(no-gutters='no-gutters')
                v-progress-circular(size='18' v-if='loading_content' class='mr-3 mt-3' indeterminate color='primary')
                v-col(cols='auto').ml-4.d-flex.btn-group-with-label-contact
                    label {{$t('FILTERSETTING.CS')}}
                    .btn-group.ml-4
                        setting_btn(:filter_list='filter_list',:tables='tables',:currentTable='currentTable',:default_filter='default_filter', :tab='mode_name', :is_staff='is_staff', :stage-type-list='[]' @emitView='onEmitView' @emitFilter='onEmitFilter' :customViews='[]')
                v-col(cols='auto').ml-4.d-flex.btn-group-with-label-contact
                    label {{$t(`ORDER_DETAIL.${this.isWiadvance() ? 'WIADVANCE_ORDER_TYPE' : 'ORDER_TYPE'}`)}}
                    .btn-group.ml-4
                        v-combobox.placeholder-black(v-model='selectOrderMode' placeholder-color="#f0f",  :items='orderTypeList' item-value='key' item-text='name' index='0' height='36' flat dense solo @change='onChangeMode(selectOrderMode.key)' )
                    .btn-group.ml-4.mr-2
                        v-text-field(v-model='searchOrder' flat dense solo prepend-inner-icon='icon-search')
                    importExportBtn(@emitFilter='onEmitFilter' :page='mode_name' :selectStageType='null' :search='searchOrder' :filter='default_filter.id')
        v-col.px-0.pt-4.pt-md-2(cols='auto' v-if='!isByIndustryShow')
            v-row.justify-md-end(no-gutters='no-gutters')
                v-progress-circular(size='18' v-if='loading_content' class='mr-3 mt-3' indeterminate color='primary')
                //- v-col(cols='auto').ml-4.d-flex.btn-group-with-label-contact
                //-     label {{$t('FILTERSETTING.CS')}}
                //-     .btn-group.ml-4
                //-         setting_btn(:filter_list='filter_list',:tables='tables',:currentTable='currentTable',:default_filter='default_filter', :tab='mode_name', :is_staff='is_staff', :stage-type-list='[]' @emitView='onEmitView' @emitFilter='onEmitFilter' :customViews='[]')
    
                v-col(cols='auto').ml-4.d-flex.btn-group-with-label-contact
                    label {{$t(`ORDER_DETAIL.${this.isWiadvance() ? 'WIADVANCE_ORDER_TYPE' : 'ORDER_TYPE'}`)}}
                    .btn-group.ml-4
                        v-combobox.placeholder-black(v-model='selectOrderMode' placeholder-color="#f0f",  :items='orderStandardTypeList' item-value='key' item-text='name' index='0' height='36' flat dense solo @change='onChangeMode(selectOrderMode.key)' )
                    .btn-group.ml-4.mr-2
                        v-text-field(v-model='searchOrder' flat dense solo prepend-inner-icon='icon-search')
        //- standerAllHeaders/orderAllProdHeaders(wiAdvance)-same data different header
        #page-inner.scroll-x.pb-0.height_100vh(v-if='mode == "all_product"')
            .data-area
                v-data-table.deals-table(v-if='!loading_content && !loading' :headers='this.isWiadvance()?orderAllProdHeaders:standerAllHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='orderTableList' :items-per-page='itemPerPage' :page.sync='page' :page-count='pageCount' height='100%' hide-default-footer='hide-default-footer' :loading='loading_edit' loading-text='loading...' item-key="index"  fixed-header :sort-desc.sync='sortByDesc' :sort-by.sync="sortBy" multi-sort)
                    //- 訂單名稱
                    template(v-slot:item.order_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                a.ellipsis(v-if='!!item.order_name' :href='"/Order/" + item.order_id' class='ellipsis ml-8') {{ item.order_name }}
                    //- 客戶名稱
                    template(v-slot:item.organization_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                a.ellipsis(v-if='!!item.organization_name' :href='"/Account/" + item.organization_id' class='ellipsis ml-8') {{ item.organization_name }}
                    //- 聯絡人
                    template(v-slot:item.contact_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                a.ellipsis(v-if='!!item.contact_name' :href='"/Contacts/" + item.contact_id' class='ellipsis ml-8') {{ item.contact_name }}
                    //- 金額
                    template(v-slot:item.amount='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.amount') {{ ' $ '+ getFormatAmount(item.amount) }}
                    //- 付款條件
                    template(v-slot:item.payment_term='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.payment_term') {{getItemNameFromList(paymentTermsList,item.payment_term)}}
                    //- 運送方式
                    template(v-slot:item.shipping_method='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='getItemNameFromList(orderShippingMethodsList,item.shipping_method)') {{getItemNameFromList(orderShippingMethodsList,item.shipping_method)}}
                    //- 運費條件
                    template(v-slot:item.shipping_term='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='getItemNameFromList(orderShippingTermList,item.shipping_term)') {{getItemNameFromList(orderShippingTermList,item.shipping_term)}}
                    //- 發票地址
                    template(v-slot:item.billing_address='{ item }')
                        div(class='d-flex justify-center' :title='item.billing_address')
                            .inline-edit-cell 
                                a.ellipsis.ml-8(:href='onGoogleMap(item.billing_address)') {{item.billing_address}}
                    //- 收件地址
                    template(v-slot:item.shipping_address='{ item }')
                        div(class='d-flex justify-center' :title='item.shipping_address')
                            .inline-edit-cell
                                a.ellipsis.ml-8(:href='onGoogleMap(item.shipping_address)') {{item.shipping_address}}
                label.text-right.mr-4(v-if='!loading_content') {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}}
                v-pagination.py-1.mb-3(v-if='!loading_content' v-model='page' :length='pageCount' circle :total-visible="10") 
        #page-inner.scroll-x.pb-0.height_100vh(v-if='mode == "license_product" && isByIndustryShow')
            .data-area
                v-data-table.deals-table(v-if='!loading_content && !loading' :headers='orderLienseHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='orderTableList' :items-per-page='itemPerPage' :page.sync='page' :page-count='pageCount' height='100%' hide-default-footer='hide-default-footer' :loading='loading_edit' loading-text='loading...' item-key="index"  fixed-header :sort-desc.sync='sortByDesc' :sort-by.sync="sortBy" multi-sort)
                    //- 訂單名稱
                    template(v-slot:item.order_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell.justify-start
                                a.ellipsis(v-if='!!item.order_name' :href='"/Order/" + item.order_id' class='ellipsis ml-8') {{ item.order_name }}
                    //- 訂單狀態
                    template(v-slot:item.order_status='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='getItemNameFromList(order_status_list,item.order_status)') {{ getItemNameFromList(order_status_list,item.order_status) }}
                    //- 負責業務
                    template(v-slot:item.licenseproductionitem_sale_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.licenseproductionitem_sale_name') {{item.licenseproductionitem_sale_name}}
                    //- 距離到期日
                    template(v-slot:item.remain_day='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(v-if='parseInt(item.order_status)>4' :title='getItemNameFromList(order_status_list,item.order_status)') {{getItemNameFromList(order_status_list,item.order_status)}}
                                span.ellipsis(v-else :title='item.remain_day') {{item.remain_day}}
                    //- 公司名稱
                    template(v-slot:item.organization_name='{ item }')
                        div(class='d-flex justify-start')
                            .inline-edit-cell-start
                                a.ellipsis(v-if='!!item.organization_name' :href='"/Account/" + item.organization_id' class='ellipsis ') {{ item.organization_name }}
                    //- 終端客戶
                    template(v-slot:item.end_account_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.end_account_name') {{item.end_account_name}}
                    //- 聯絡人
                    template(v-slot:item.licenseproductionitem_contact_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                a.ellipsis(v-if='!!item.licenseproductionitem_contact_name' :href='"/Contacts/" + item.contact_id' class='ellipsis ml-8') {{ item.licenseproductionitem_contact_name }}
                    //- 聯絡人2
                    template(v-slot:item.licenseproductionitem_contact_second_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                a.ellipsis(v-if='!!item.licenseproductionitem_contact_second_name' :href='"/Contacts/" + item.contact_second_id' class='ellipsis ml-8') {{ item.licenseproductionitem_contact_second_name }}
                    //- 聯絡人3
                    template(v-slot:item.licenseproductionitem_contact_third_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                a.ellipsis(v-if='!!item.licenseproductionitem_contact_third_name' :href='"/Contacts/" + item.contact_third_id' class='ellipsis ml-8') {{ item.licenseproductionitem_contact_third_name }}
                    //- Type 合約種類
                    template(v-slot:item.product_type_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.product_type_name') {{item.product_type_name}}
                    //- 計費頻率
                    template(v-slot:item.billing_frequency='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='getItemNameFromList(billing_frequency_list,item.billing_frequency)') {{getItemNameFromList(billing_frequency_list,item.billing_frequency)}}
                    //- 項目編號
                    template(v-slot:item.product_code='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.product_code') {{item.product_code}}
                    //- 產品品名
                    template(v-slot:item.product_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.product_name') {{item.product_name}}
                    //- 套數
                    template(v-slot:item.quantity='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.quantity') {{item.quantity}}
                    //- 購買/啟用日期
                    template(v-slot:item.start_date='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.start_date') {{item.start_date}}
                    //- 結束日期
                    template(v-slot:item.end_date='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.end_date') {{item.end_date}}
                    //- 訂單編號
                    template(v-slot:item.order_number='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.order_number') {{item.order_number}}
                    //- 狀態
                    template(v-slot:item.status='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='getItemNameFromList(status_list,item.status)') {{getItemNameFromList(status_list,item.status)}}
                    //- 註記
                    template(v-slot:item.note='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.note') {{item.note}}
                    //- SO
                    template(v-slot:item.sales_order_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title="item.sales_order_name") {{item.sales_order_name}}
                    //- PO
                    template(v-slot:item.purchase_order='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.purchase_order') {{item.purchase_order}}
                    //- 專案編號
                    template(v-slot:item.project_code='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.project_code') {{item.project_code}}
                label.text-right.mr-4(v-if='!loading_content') {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}}
                v-pagination.py-1.mb-3(v-if='!loading_content' v-model='page' :length='pageCount' circle :total-visible="10") 
        #page-inner.scroll-x.pb-0.height_100vh(v-if='mode == "service_product" && isByIndustryShow')
            .data-area
                v-data-table.deals-table(v-if='!loading_content && !loading' :headers='orderServiceHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='orderTableList' :items-per-page='itemPerPage' :page.sync='page' :page-count='pageCount' height='100%' hide-default-footer='hide-default-footer' :loading='loading_edit' loading-text='loading...' item-key="index"  fixed-header :sort-desc.sync='sortByDesc' :sort-by.sync="sortBy" multi-sort)
                    //- 訂單名稱
                    template(v-slot:item.order_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell.justify-start
                                a.ellipsis(v-if='!!item.order_name' :href='"/Order/" + item.order_id' class='ellipsis ml-8') {{ item.order_name }}
                    //- 訂單狀態
                    template(v-slot:item.order_status='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='getItemNameFromList(order_status_list,item.order_status)') {{ getItemNameFromList(order_status_list,item.order_status) }}
                    //- 負責業務
                    template(v-slot:item.serviceproductitem_sale_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.serviceproductitem_sale_name') {{item.serviceproductitem_sale_name}}
                    //- 距離到期日期         
                    template(v-slot:item.remain_day='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(v-if='parseInt(item.order_status)>4' :title='getItemNameFromList(order_status_list,item.order_status)') {{getItemNameFromList(order_status_list,item.order_status)}}
                                span.ellipsis(v-else :title='item.remain_day') {{item.remain_day}}
                    //- 公司名稱
                    template(v-slot:item.organization_name='{ item }')
                        div(class='d-flex justify-start')
                            .inline-edit-cell-start
                                a.ellipsis(v-if='!!item.organization_name' :href='"/Account/" + item.organization_id' class='ellipsis ') {{ item.organization_name }}
                    //- 終端客戶
                    template(v-slot:item.end_account_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.end_account_name') {{item.end_account_name}}
                    //- 聯絡人
                    template(v-slot:item.serviceproductitem_contact_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                a.ellipsis(v-if='!!item.serviceproductitem_contact_name' :href='"/Contacts/" + item.contact_id' class='ellipsis ml-8') {{ item.serviceproductitem_contact_name }}
                    //- 聯絡人2
                    template(v-slot:item.serviceproductitem_contact_second_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                a.ellipsis(v-if='!!item.serviceproductitem_contact_second_name' :href='"/Contacts/" + item.contact_second_id' class='ellipsis ml-8') {{ item.serviceproductitem_contact_second_name }}
                    //- 聯絡人3
                    template(v-slot:item.serviceproductitem_contact_third_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                a.ellipsis(v-if='!!item.serviceproductitem_contact_third_name' :href='"/Contacts/" + item.contact_third_id' class='ellipsis ml-8') {{ item.serviceproductitem_contact_third_name }}
                    //- 合約種類
                    template(v-slot:item.product_type_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.product_type_name') {{item.product_type_name}}
                    //- 專案名稱
                    template(v-slot:item.project_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.project_name') {{item.project_name}}
                    //- SLA
                    template(v-slot:item.sla='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='getItemNameFromList(sla_list,item.sla)') {{ getItemNameFromList(sla_list,item.sla) }}
                    //- 維運內容
                    template(v-slot:item.service_content='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.service_content') {{item.service_content}}
                    //- 購買/啟用日期
                    template(v-slot:item.start_date='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.start_date') {{item.start_date}}
                    //- 結束日期
                    template(v-slot:item.end_date='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.end_date') {{item.end_date}}
                    //- 維運方案
                    template(v-slot:item.service_plan='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='getItemNameFromList(service_plan_list,item.service_plan)') {{ getItemNameFromList(service_plan_list,item.service_plan) }}
                    //- 狀態
                    template(v-slot:item.status='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='getItemNameFromList(status_list,item.status)') {{ getItemNameFromList(status_list,item.status) }}
                    //- 註記
                    template(v-slot:item.note='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.note') {{item.note}}
                    //- SO
                    template(v-slot:item.sales_order_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title="item.sales_order_name") {{item.sales_order_name}}
                    //- PO
                    template(v-slot:item.purchase_order='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.purchase_order') {{item.purchase_order}}
                    //- 專案編號
                    template(v-slot:item.project_code='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.project_code') {{item.project_code}}
                label.text-right.mr-4(v-if='!loading_content') {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}}
                v-pagination.py-1.mb-3(v-if='!loading_content' v-model='page' :length='pageCount' circle :total-visible="10")
        //- standard table -product
        #page-inner.scroll-x.pb-0.height_100vh(v-if='mode == "standard_order" && !isByIndustryShow') 
            .data-area
                v-data-table.deals-table(v-if='!loading_content && !loading' :headers='standardOrderHeader' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='orderTableList' :items-per-page='itemPerPage' :page.sync='page' :page-count='pageCount' height='100%' hide-default-footer='hide-default-footer' :loading='loading_edit' loading-text='loading...' item-key="index"  fixed-header :sort-desc.sync='sortByDesc' :sort-by.sync="sortBy" multi-sort)
                    //- 訂單名稱
                    template(v-slot:item.order_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell.justify-start
                                a.ellipsis(v-if='!!item.order_name' :href='"/Order/" + item.order_id' class='ellipsis ml-8') {{ item.order_name }}
                    //- 客戶名稱
                    template(v-slot:item.organization_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell.justify-start
                                a.ellipsis(v-if='!!item.organization_name' :href='"/Account/" + item.order_organization' class='ellipsis ml-8') {{ item.organization_name }}
                    //- 聯絡人
                    template(v-slot:item.contact_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell.justify-start
                                a.ellipsis(v-if='!!item.contact_name' :href='"/Contacts/" + item.order_contact' class='ellipsis ml-8') {{ item.contact_name }}
                    //- 產品
                    template(v-slot:item.product_name='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.product_name') {{ item.product_name }}
                    //- 定價
                    template(v-slot:item.op_price='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.op_price') {{ ' $ '+ getFormatAmount(item.op_price) }}
                    //- 數量
                    template(v-slot:item.quantity='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.quantity') {{ getFormatAmount(item.quantity) }}
                    //- 其他費用
                    template(v-slot:item.other_charges='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.other_charges') {{ getFormatAmount(item.other_charges) }}
                    //- 折扣
                    template(v-slot:item.discount='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.discount') {{ getFormatAmount(item.discount) }}
                    //- 稅
                    template(v-slot:item.tax='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.tax') {{ getFormatAmount(item.tax) }}
                    //- 金額
                    template(v-slot:item.net_amount='{ item }')
                        div(class='d-flex justify-center')
                            .inline-edit-cell
                                span.ellipsis(:title='item.net_amount') {{ ' $ '+ getFormatAmount(item.net_amount) }}
                            
                label.text-right.mr-4(v-if='!loading_content') {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}}
                v-pagination.py-1.mb-3(v-if='!loading_content' v-model='page' :length='pageCount' circle :total-visible="10")

        v-dialog(v-model='addOrderDialog' max-width='630' @keydown.esc='onEmitAddOrderDialog(false)' v-if='addOrderDialog')
            add-order(@emitAddOrderDialog='onEmitAddOrderDialog' :add-order='addOrder')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')

</template>
<script>
    import Vue from "vue";
    import i18n from '/common/plugins/vue-i18n.js'
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import OrderDataService from "@/services/OrderDataService";
    import PreferenceService from "@/services/PreferenceService";
    import CustomFilterDataService from "@/services/CustomFilterDataService"; 
    import orderShippingMethodsList from "@/array/the_orderShippingMethodsList";
    import paymentTermsList from "@/array/the_paymentTermsList";
    import productLineItemLists from "@/array/the_productLineItemLists";
    // dialog
    import addOrder from '@/components/Dialog/Order/addOrder';
    import messageDialog from '@/components/Dialog/messageDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import successDialog from "@/components/Dialog/successDialog";
    // other
    import setting_btn from "@/components/Common/setting_btn";
    import importExportBtn from "@/components/Common/importExportBtn";

    export default Vue.extend({
        name: 'Orders',
        components: {
            addOrder,
            messageDialog,
            errorDialog,
            successDialog,
            loadingOverlay,
            setting_btn,
            importExportBtn
        },
        data() {
            return {
                filter_list:[],
                tables:[],
                current_table:{},
                default_filter:null,
                system_default_filter: null,
                mode_name:"Order",
                loading: false,
                addOrderDialog: false,
                addOrder: {},
                selectOrderMode: {
                    key: 0,
                    name: i18n.t('GENERAL.ALL')
                },
                orderTypeList: [
                    {
                        key: 0,
                        name: i18n.t('GENERAL.ALL')
                    },
                    {
                        key: 1,
                        name: i18n.t('ORDER_TYPE.LICENSE_PRODUCT')
                    },
                    {
                        key: 2,
                        name: i18n.t('ORDER_TYPE.SERVICE_PRODUCT')
                    },
                ],
                orderStandardTypeList: [
                    {
                        key: 0,
                        name: i18n.t('GENERAL.ALL')
                    },
                    {
                        key: 3,
                        name: i18n.t('GENERAL.PRODUCT')
                    },
                ],
                mode: 'all_product',
                isByIndustryShow: false,
                // listing view
                electedRows: [],
                loading_content: true,
                loading_edit: false,
                page: 1,
                pageCount: 0,
                itemPerPage: 50,
                pageDataCount:null,
                currentData:0,
                orderTableList: [],
                standerAllHeaders: [
                    {
                        text: i18n.t(`ORDER.${this.isWiadvance()  ? 'WIADVANCE_NAME' : 'NAME'}`),
                        value: "order_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.CLIENT_NAME'),
                        value: "organization_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.CONTACT_NAME'),
                        value: "contact_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.AMOUNT'),
                        value: "amount",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.PAYMENT_TERM'),
                        value: "payment_term",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.SHIPPING_METHOD'),
                        value: "shipping_method",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.SHIPPING_TERM'),
                        value: "shipping_term",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.BILLING_ADDRESS'),
                        value: "billing_address",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.SHIPPING_ADDRESS'),
                        value: "shipping_address",
                        align: "center",
                        width: '200px',
                    },
                ],
                standardOrderHeader: [
                    {
                        text: i18n.t(`ORDER.${this.isWiadvance()  ? 'WIADVANCE_NAME' : 'NAME'}`),
                        value: "order_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.CLIENT_NAME'),
                        value: "organization_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.CONTACT_NAME'),
                        value: "contact_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('PRODUCTSETTING.PRODUCT'),
                        value: "product_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('PRODUCTSETTING.PRICR'),
                        value: "op_price",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('PRODUCTLINEITEM.QUANTITY'),
                        value: "quantity",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('PRODUCTLINEITEM.OTHER_CHARGES')+" (%)",
                        value: "other_charges",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('PRODUCTLINEITEM.DISCOUNT')+" (%)",
                        value: "discount",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('PRODUCTLINEITEM.TAX')+" (%)",
                        value: "tax",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('PRODUCTLINEITEM.AMOUNT'),
                        value: "net_amount",
                        align: "center",
                        width: '200px',
                    },
                ],
                // wiAdavance customer Header
                orderAllProdHeaders: [
                    {
                        text: i18n.t(`ORDER.${this.isWiadvance()  ? 'WIADVANCE_NAME' : 'NAME'}`),
                        value: "order_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.CLIENT_NAME'),
                        value: "organization_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.CONTACT_NAME'),
                        value: "contact_name",
                        align: "center",
                        width: '200px',
                    },
                ],
                orderLienseHeaders: [
                    {
                        text: i18n.t(`ORDER.${this.isWiadvance()  ? 'WIADVANCE_NAME' : 'NAME'}`),
                        value: "order_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.ORDER_STATUS'),
                        value: "order_status",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.ORDER_SALES'),
                        value: "licenseproductionitem_sale_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.FROM_DATE'),
                        value: "remain_day",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.ORGANIZATION_NAME'),
                        value: "organization_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.ENDACCOUNT'),
                        value: "end_account_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.CONTACT_NAME'),
                        value: "licenseproductionitem_contact_name",
                        align: "center",
                        width: '200px',
                    },
                    // {
                    //     text: i18n.t('ORDER.CONTACT_NAME')+" 2",
                    //     value: "licenseproductionitem_contact_second_name",
                    //     align: "center",
                    //     width: '200px',
                    // },
                    // {
                    //     text: i18n.t('ORDER.CONTACT_NAME')+" 3",
                    //     value: "licenseproductionitem_contact_third_name",
                    //     align: "center",
                    //     width: '200px',
                    // },
                    {
                        text: i18n.t('ORDER.TYPE'),
                        value: "product_type_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.BILLING_FREQUENCY'),
                        value: "billing_frequency",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.PRODUCT_CODE'),
                        value: "product_code",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.PRODUCT_NAME'),
                        value: "product_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.QUANTITY'),
                        value: "quantity",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.PURCHASE_ACTIVATION_DATE'),
                        value: "start_date",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.DUE_DATE'),
                        value: "end_date",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.ORDER_NUMBER'),
                        value: "order_number",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.STATUS'),
                        value: "status",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.NOTE'),
                        value: "note",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: 'SO',
                        value: "sales_order_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: 'PO',
                        value: "purchase_order",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.PROJECT_CODE'),
                        value: "project_code",
                        align: "center",
                        width: '200px',
                    },
                ],
                orderServiceHeaders: [
                    {
                        text: i18n.t(`ORDER.${this.isWiadvance()  ? 'WIADVANCE_NAME' : 'NAME'}`),
                        value: "order_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.ORDER_STATUS'),
                        value: "order_status",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.ORDER_SALES'),
                        value: "serviceproductitem_sale_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.FROM_DATE'),
                        value: "remain_day",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.ORGANIZATION_NAME'),
                        value: "organization_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.ENDACCOUNT'),
                        value: "end_account_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.CONTACT_NAME'),
                        value: "serviceproductitem_contact_name",
                        align: "center",
                        width: '200px',
                    },
                    // {
                    //     text: i18n.t('ORDER.CONTACT_NAME')+" 2",
                    //     value: "serviceproductitem_contact_second_name",
                    //     align: "center",
                    //     width: '200px',
                    // },
                    // {
                    //     text: i18n.t('ORDER.CONTACT_NAME')+" 3",
                    //     value: "serviceproductitem_contact_third_name",
                    //     align: "center",
                    //     width: '200px',
                    // },
                    {
                        text: i18n.t('ORDER.TYPE'),
                        value: "product_type_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.PROJECT_NAME'),
                        value: "project_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: 'SLA',
                        value: "sla",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.SERVICE_CONTENT'),
                        value: "service_content",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.START_DATE'),
                        value: "start_date",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.ACCEPTANCE_COMPLETION_DATE'),
                        value: "end_date",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.SERVICE_PLAN'),
                        value: "service_plan",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.STATUS'),
                        value: "status",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.NOTE'),
                        value: "note",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: 'SO',
                        value: "sales_order_name",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: 'PO',
                        value: "purchase_order",
                        align: "center",
                        width: '200px',
                    },
                    {
                        text: i18n.t('ORDER.PROJECT_CODE'),
                        value: "project_code",
                        align: "center",
                        width: '200px',
                    },
                ],
                // dialog
                messageDialog: false,
                message: '',
                errorDialog: false,
                errorMessage: '',
                successDialog: false,
                successMessage: "",
                formatAmount: null,
                orderShippingTermList: [
                    { key: "FOB", name: 'FOB' },
                    { key: "free_shipping", name: i18n.t('SHIPPINH_TERM.FREE_SHIPPING') },
                ],
                orderShippingMethodsList: orderShippingMethodsList,
                paymentTermsList: paymentTermsList,
                productLineItemLists:productLineItemLists,
                order_status_list: productLineItemLists['order_status_list'],
                sla_list : productLineItemLists['sla_list'],
                service_plan_list : productLineItemLists['wiadvance_service_plan_list'],
                status_list : productLineItemLists['status_list'],
                billing_frequency_list : productLineItemLists['billing_frequency_list'],
                currentTable : {
                    view_id: 'license_product'
                },
                headerData:{
                    id: 'license_product',
                    name:'',
                    value:{},
                    page:"Order",
                },
                isTechUser: false,
                is_staff: false,
                first_run:false,
                is_change_mode:false,
                searchOrder:'',
                awaitingSearch: false,
                sortBy:[],
                sortByForSearch:[],
                sortByDesc:[],
            }
        },
        async created(){
            this.loading = true;
            this.checkTechUser()
            let customWidthData = await this.getWidth("Order",this.currentTable, this.mode);
            this.headerData.id = this.mode;
            this.headerData.name = this.mode;
            this.headerData.value = this.orderLienseHeaders;
            this.headerData.page = "Order";
            this.headerData.customWidth = customWidthData;

            let filter_id = this.getDefaultFilter(this.mode_name,null)
            await (this.getFilterList())
            await (this.default_filter = this.getPageDefaultFilter(filter_id,this.filter_list, this.system_default_filter));

            await this.getListData();
            PreferenceService.getINDUSTRY().then(response => 
            {
                let data = response.data;
                if (data == "IT"){
                    this.isByIndustryShow = true;
                    this.mode = 'all_product';
                }
            });
            await( this.loading = false )
            await( this.first_run = true )
        },
        computed: {

        },
        destroyed() {

        },
        methods: {
                async getFilterList(){
                    await CustomFilterDataService.list()
                    .then((response)=>{
                        this.filter_list = response.data
                        this.filter_list = this.filter_list.filter(el => el.class_name == this.mode_name)
                    })
                    .finally(()=>{
                        this.system_default_filter = this.filter_list.find(e => e.default);
                    })
                },
                onEmitFilter(filter, situation){
                    if(situation == 'cancelEdit'){
                        for(let prop in this.default_filter){
                            let p = prop.toString();
                            this.$set(this.default_filter, p, filter[prop]) 
                        }
                        return;
                    }
                    if(situation == 'applyFilter' && filter != undefined){
                        filter.owner_id = filter.owner;
                        this.default_filter = filter;
                        return;
                    }
                    if(situation == 'addFilter' && filter != undefined){
                        this.filter_list.push(filter);
                        filter.owner_id = filter.owner;
                        this.default_filter = filter;
                        return;
                    }
                    if(situation == 'confirmEdit_exportDialog'){
                        let oldData = this.filter_list.find(e => e.id == filter.id);
                        for(let prop in oldData){
                            let p = prop.toString();
                            this.$set(oldData, p, filter[prop]) 
                        }
                        return;
                    }
            },
            async getListData(){
                // this.getHeader(this.mode);
                this.loading_content = true;
                await OrderDataService.list(this.page,this.itemPerPage,this.mode,this.default_filter.id,this.searchOrder,this.sortByForSearch)
                .then(response => {
                    this.orderTableList = response.data.list.map(orderlist=>{
                        if(orderlist.sales_order){
                            orderlist.sales_order = JSON.parse(orderlist.sales_order);
                        }
                        if(orderlist.end_date){
                            orderlist.remain_day = this.daysUntilDue(orderlist.end_date)
                        }
                        return orderlist;
                    })
                    this.pageCount = response.data.total_page;
                    this.pageDataCount = response.data.pageDataCount;
                    this.currentData = (this.page)*this.itemPerPage >= this.pageDataCount ? this.pageDataCount:(this.page)*this.itemPerPage;
                })
                .then(()=>{
                    this.loading_content = false;
                    this.is_change_mode = false;
                })
            },
            showAddOrderDialog () {
                this.addOrder = {
                    name:"",
                    organization: null,
                    contact: null,
                    amount: 0,
                    shippping_method: "",
                    shipping_term: "",
                    payment_term: "",
                    billing_address: "",
                    shipping_address: "",
                }
                this.addOrderDialog = true;
            },
            onEmitAddOrderDialog (val) {
                if(val){
                    this.showSuccessDialog();
                    this.getListData();
                }
                this.addOrderDialog = false;
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog() {
                this.messageDialog = false;
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
            },
            async onChangeMode(mode) {
                this.loading_content = true;
                this.is_change_mode = true;
                this.sortBy = [];
                this.sortByForSearch = [];
                this.sortByDesc = [];
                this.searchOrder = "";
                switch(mode){
                    case 0:
                        this.mode = 'all_product';
                        this.mode_name = "Order";
                        break;
                    case 1:
                        this.mode = 'license_product';
                        this.mode_name = "Order_License";
                        break;
                    case 2:
                        this.mode = "service_product";
                        this.mode_name = "Order_Service";
                        break;
                    case 3:
                        this.mode = "standard_order";
                        this.mode_name = "Order_Standard";
                        break
                }
                let filter_id = this.getDefaultFilter(this.mode_name,null)
                await (this.getFilterList())
                await (this.default_filter = this.getPageDefaultFilter(filter_id,this.filter_list, this.system_default_filter));
                await this.getListData();
            },
            daysUntilDue(dueDate){
                let today = new Date();
                today.setHours(0, 0, 0, 0);
                let due = new Date(dueDate);
                due.setHours(0, 0, 0, 0);
                let diffTime = due - today;
                let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                return diffDays;
            },
            onGoogleMap: function (address) {
                return "https://www.google.com.tw/maps/place/" + address;
            },
            checkTechUser(){
                let user = JSON.parse(window.localStorage.getItem('user'));
                if(user){
                    this.isTechUser = JSON.parse(window.localStorage.getItem('user'))['is_tech_user'];
                    this.is_staff = JSON.parse(window.localStorage.getItem('user'))['is_staff'];
                }
            },
            checkWiadvanceReadonly(){
                if(this.isWiadvance()){
                    return !this.is_staff || this.isTechUser  ? false : true;
                }else{
                    return true
                }
            },
            onEmitView(){
            },
        },
        watch: {
            "sortByDesc":async function () {   
                if(this.is_change_mode) return;
                this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
                for(let i in this.sortByDesc)
                {
                    if(this.sortByDesc[i] == true)
                    {
                        this.sortByForSearch[i] = '-'+this.sortByForSearch[i]          
                    }        
                }
                await this.getListData();
            },
            "searchOrder":async function () {
                if(this.is_change_mode) return;
                if (!this.awaitingSearch) {
                    setTimeout(() => {
                        this.page = 1
                        this.getListData();
                        this.awaitingSearch = false;
                    }, 1000);
                    }
                this.awaitingSearch = true;
            }, 
            "default_filter":function () {
                if(this.first_run && !this.is_change_mode){
                    this.page = 1;
                    this.getListData()
                } 
            },
            "page":async function () {
                await this.getListData()
            },
            // "mode": async function () {
            //     await this.getListData()
            // },
        }
    })
</script>